import React from 'react';
import Countdown from 'react-countdown';
import './ComingSoon.css'; // Custom styles

const ComingSoon = () => {
  // Renderer for countdown timer
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown">
        <div className="countdown-item">
          <span>{days}</span>
          <span>Days</span>
        </div>
        <div className="countdown-item">
          <span>{hours}</span>
          <span>Hours</span>
        </div>
        <div className="countdown-item">
          <span>{minutes}</span>
          <span>Minutes</span>
        </div>
        <div className="countdown-item">
          <span>{seconds}</span>
          <span>Seconds</span>
        </div>
      </div>
    );
  };

  return (
    <div className="coming-soon">
      <h1>Coming Soon</h1>
      <p>We're working hard to give you a better experience. Stay tuned!</p>
      {/* <Countdown date={Date.now() + 1000000000} renderer={renderer} /> */}
    </div>
  );
};

export default ComingSoon;
