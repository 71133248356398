import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CardComponent from '../components/CardComponent/CardComponent';

import Home from '../components/Static/LandingPage/Home';




import Login from '../components/Auth/Login/Login';
import Signup from '../components/Auth/Signup/Signup';
import SecondSignup from '../components/Auth/Signup/SecondSignup';
import ForgotPassword from '../components/Auth/Signup/ForgotPassword';
import Otp from '../components/Auth/Signup/Otp';

import ComingSoon from '../components/ComingSoon/ComingSoon';
const Roots = () => {
    return (
        <BrowserRouter>
 
        <Routes>
        
        <Route path="/" element={<ComingSoon/>} />
      
        {/* <Route path="/" element={<Home/>} /> */}
    
        {/* <Route path="/CardComponent" element={<CardComponent/>} />
      
  
        <Route path="/signup" element={<Signup/>} />
        <Route path="/second-signup" element={<SecondSignup/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/otp" element={<Otp/>} /> */}
       
       
  
        </Routes>
      </BrowserRouter>
    )
}

export default Roots;
